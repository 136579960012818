import { lazy } from 'react';
import { LandingProvider } from 'src/contexts/LandingContext';
import { element } from 'prop-types';

export const landingRoute = (loader: any) => {
    const LandingPage = loader(
        lazy(() => import('src/layouts/LandingLayout'))
    );

    const LandingIndex = loader(lazy(() => import('src/pages/landing/Index')))

    return [
        {
            path: '/',
            element: (
                <>
                    <LandingProvider>
                        <LandingPage>
                            <LandingIndex />
                        </LandingPage>
                    </LandingProvider>
                </>
            ),

        },

    ];
};
