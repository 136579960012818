import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  ChangeEvent
} from 'react';
import {
  getList,
  getDetail,
  createItem,
  updateItem,
  deleteItem
} from '../api/api';
import { Pagination } from 'src/utils/type/PaginationInterface';
import {
  Employee,
  EmployeeContextProps,
  EmployeeQuery,
  EmployeeFormValues
} from './interface/EmployeeContextInterface';
import { useNavigate, useParams } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { swalDeleteConfig } from 'src/utils/swal/Index';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import {
  defaultInitialPagination,
  defaultQuery
} from 'src/utils/pagination.util';

const EmployeeContext = createContext<EmployeeContextProps | undefined>(
  undefined
);

export const useEmployee = (): EmployeeContextProps => {
  const context = useContext(EmployeeContext);
  if (!context) {
    throw new Error('useEmployee must be used within an EmployeeProvider');
  }
  return context;
};

const RESOURCE = 'employees';

export const EmployeeProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [query, setQuery] = useState<EmployeeQuery>(defaultQuery);
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [pagination, setPagination] = useState<Pagination>(
    defaultInitialPagination
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<EmployeeFormValues>({
    name: null,
    number: 0,
    position: null,
    department: null,
    joinDate: null,
    status: null,
    photo: null
  });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routerPush = (url: string) => navigate(url);
  const fetchEmployees = async (q: Partial<EmployeeQuery> = {}) => {
    setLoading(true);
    try {
      const response = await getList(RESOURCE, { ...query, ...q });
      const { data, meta } = response;
      setPagination(meta.pagination);
      setEmployeeList(data);
    } catch (error) {
      toast.error('Failed to fetch employees');
    } finally {
      setLoading(false);
    }
  };

  const getDetailEmployee = async (id: number) => {
    setLoading(true);
    try {
      if (id) {
        const response = await getDetail(RESOURCE, id);
        const { data } = response;
        setInitialValues({
          name: data.name,
          number: data.number,
          position: data.position,
          department: data.department,
          joinDate: data.joinDate,
          status: data.status,
          photo: data.photo
        });
      } else {
        setInitialValues({
          name: null,
          number: null,
          position: null,
          department: null,
          joinDate: null,
          status: null,
          photo: null
        });
      }
    } catch (error) {
      toast.error('Failed to fetch employee');
    } finally {
      setLoading(false);
    }
  };

  const addEmployee = async (employee: Employee) => {
    setLoading(true);
    try {
      const newEmployee = await createItem(RESOURCE, employee);
      setEmployeeList((prev) => [...prev, newEmployee]);
      toast.success('Employee added successfully');
    } catch (error) {
      console.error('Failed to add employee', error);
      toast.error('Failed to add employee');
    } finally {
      setLoading(false);
    }
  };

  const updateEmployee = async (id: number, employee: Employee) => {
    setLoading(true);
    try {
      await updateItem(RESOURCE, id, employee);
      await fetchEmployees();
      toast.success('Employee updated successfully');
    } catch (error) {
      console.error('Failed to update employee', error);
      toast.error('Failed to update employee');
    } finally {
      setLoading(false);
    }
  };

  const deleteEmployee = async (id: number) => {
    Swal.fire({ ...swalDeleteConfig }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        try {
          if (await deleteItem(RESOURCE, id)) await fetchEmployees();
          Swal.fire('Deleted!', 'Employee has been deleted.', 'success');
        } catch (error) {
          toast.error('Failed to delete employee');
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleImportCSV = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setLoading(true);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: async (results) => {
          const importedData = results.data.map((item: any) => ({
            name: item.nama,
            number: parseInt(item.nomor),
            position: item.jabatan,
            department: item.departmen,
            joinDate: item.tanggal_masuk,
            photo: item.foto,
            photoPath: null,
            status: item.status
          }));

          for (const employee of importedData) {
            try {
              await createItem(RESOURCE, employee);
            } catch (error) {
              console.error('Failed to import employee', error);
              toast.error(`Failed to import employee: ${employee.name}`);
            }
          }

          toast.success('Employees imported successfully');
          setLoading(false);
          fetchEmployees();
        }
      });
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>, newPage: number) =>
    setQuery((prev) => ({ ...prev, page: newPage + 1 }));

  const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) =>
    setQuery((prev) => ({ ...prev, limit: parseInt(e.target.value, 10) }));

  const handleSubmit = async (values: EmployeeFormValues) => {
    setLoading(true);
    const employeeData = {
      name: values.name,
      number: values.number,
      position: values.position,
      department: values.department,
      joinDate: new Date(values.joinDate),
      status: values.status,
      photo: values.photo,
      photoPath: values.photoPath
    };
    if (id) await updateEmployee(parseInt(id), employeeData);
    else await addEmployee(employeeData);

    setLoading(false);
    navigate('/admin/employee');
  };

  const handleSort = (column: string) => {
    const isAsc = query.sortBy === column && query.sortOrder === 'asc';
    setQuery({ ...query, sortBy: column, sortOrder: isAsc ? 'desc' : 'asc' });
  };

  const handleSearchChange = (event: any) => {
    if (event.key === 'Enter')
      setQuery((prev) => ({ ...prev, search: event.target.value }));
  };

  useEffect(() => {
    getDetailEmployee(parseInt(id));
  }, [id]);

  const handleSubmitQueryPerColumn = (value: any) => {
    setQuery({ ...value });
    return fetchEmployees(value);
  };

  useEffect(() => {
    fetchEmployees();
  }, [query]);

  return (
    <EmployeeContext.Provider
      value={{
        pagination,
        employeeList,
        fetchEmployees,
        addEmployee,
        updateEmployee,
        deleteEmployee,
        getDetailEmployee,
        onPageChange,
        onRowsPerPageChange,
        handleImportCSV,
        handleSubmit,
        loading,
        query,
        routerPush,
        initialValues,
        handleSort,
        handleSearchChange,
        handleSubmitQueryPerColumn,
        id,
      }}
    >
      <ToastContainer />
      {children}
    </EmployeeContext.Provider>
  );
};
